small {
  font-size: inherit; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400; }

.title {
  line-height: 1;
  color: $c-title;
  font-weight: 800;
  text-transform: uppercase;
  display: block;
  small {
    font-size: em(34, 40);
    font-weight: 400; }
  &--h2 {
    @include css-lock(21, 42, 320, 1399, font-size); }
  &--h2-md {
    @include css-lock(21, 40, 320, 1399, font-size); }
  &--h3 {
    @include css-lock(18, 24, 320, 1399, font-size); }
  &--h5 {
    font-size: 1em !important;
    font-weight: 700; }
  &--h6 {
    font-size: $fz-xs !important;
    font-weight: 700; }
  &--white {
    color: $white; }
  &--accent {
    color: $accent; }
  &--ttn {
    text-transform: none; }
  &--bold {
    font-weight: 700; } }

.h2-subttl {
  text-transform: uppercase;
  font-weight: 600;
  @include css-lock(16, 21, 320, 1399, font-size); }

.text {
  &--white {
    color: $white; } }
