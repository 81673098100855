$fz:       16px;
$fz-sm-px: 14px;
$fz-xs-px: 12px;
$fz-sm:    em(14);
$fz-xs:    em(12);

$c-text: #838383;
$c-title: #333333;

$black: #000;
$white: #fff;
$accent: #e55d51;

$gray-lightest: #f7f7f7;
$gray-light: #b6b6b6;
$gray: #acacac;

$blue: #5fa0dd;

$header-top-height: 70px;
$header-top-height-xll: 70px;
$header-top-height-lg: 70px;
$header-top-height-sm: 50px;

$header-bottom-height: 70px;
$header-bottom-height-xll: 50px;

$header-height: $header-top-height + $header-bottom-height;
$header-height-xll: $header-top-height-xll + $header-bottom-height-xll;
$header-height-lg: $header-top-height-lg;
$header-height-sm: $header-top-height-sm;
