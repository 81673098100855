.footer {
  background-color: $c-title;
  padding-top: 110px;
  @include xl {
    padding-top: 80px; }
  @include lg {
    padding-top: 60px; }
  @include md {
    padding-top: 50px; }
  @include sm {
    padding-top: 30px; }
  &__auth {
    margin-top: 80px;
    @include flex(center, center);
    font-size: em(13);
    a {
      color: $white;
      text-decoration: underline; }
    @include xl {
      margin-top: 60px; }
    @include lg {
      margin-top: 50px; }
    @include md {
      margin-top: 40px; }
    @include sm {
      margin-top: 30px; } }
  &__auth-icon {
    @include flex(center, center);
    color: $white;
    background-color: $black;
    width: 40px;
    height: 40px;
    margin-right: .7em;
    .icon {
      font-size: 20px; } }
  &__title {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; } }
  &__logo {
    margin-bottom: 15px; }
  &__text {
    margin-bottom: 40px;
    // max-width: 350px
    img {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      margin-right: 5px;
      margin-top: -.2em; }
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } }
  &__block {
    @include md {
      margin-bottom: 30px; } } }
