.header {
  $this: &;
  position: relative;
  z-index: 10;
  @include lg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0; }
  &.is-fixed {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    #{$this}__top {
      display: none; } }
  &__top {
    background-color: $white;
    #{$this}__inner {
      height: $header-top-height;
      justify-content: flex-start;
      padding-top: 5px;
      padding-bottom: 5px;
      @include xll {
        height: $header-top-height-xll; }
      @include lg {
        height: $header-top-height-lg;
        box-shadow: 0 0 10px rgba($black, .2); }
      @include sm {
        height: $header-top-height-sm; } } }
  &__bottom {
    background-color: $c-title;
    &.is-fixed {
      position: fixed;
      left: 0;
      right: 0;
      top: 0; }
    @include lg {
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      padding-top: 40px;
      padding-bottom: 40px;
      text-align: center;
      @include ready {
        @include tr(opacity .3s, visibility .3s); }
      @include vertical;
      @include hidden;
      .container {
        display: inline-block;
        vertical-align: middle;
        font-size: $fz;
        width: 100%;
        @include sm {
          font-size: $fz-sm-px; } }
      &.is-active {
        @include visible; } }
    #{$this}__inner {
      height: $header-bottom-height;
      justify-content: center;
      flex-wrap: nowrap;
      @include xll {
        height: $header-bottom-height-xll; }
      @include lg {
        height: auto;
        display: block;
        margin-left: 0;
        margin-right: 0; } } }
  &__inner {
    @extend .row;
    align-items: center; }
  &__contacts {
    @extend .col-xl-5;
    @include flex(space-between, center);
    @include lg {
      max-width: none;
      flex: 1 0 auto;
      width: auto; }
    @include sm {
      justify-content: flex-end; }
    @include xs {
      padding-left: 0;
      padding-right: 0; } }
  &__logo {
    @extend .col-xl-2;
    @include lg {
      max-width: none;
      flex: 0 0 auto;
      width: auto; }
    @include xs {
      padding-right: 0; } }
  &__btn {
    // @extend .col-4
    text-align: center;
    max-width: 17%;
    .btn {
      min-width: 0; }
    @include lg {
      display: none; } }
  &__btn-mob {
    display: none;
    @include lg {
      display: block; } }
  &__work-hrs {
    @extend .col-2;
    @include lg {
      display: none; } }
  &__nav {
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } }
  &__nav-in {}
  &__burger {
    text-align: right;
    display: none;
    padding-left: 15px;
    padding-right: 15px;
    @include lg {
      display: block; }
    @include xs {
      padding-left: 10px; } }
  &__close {
    @include icon-close(20, 2, currentColor);
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    display: none;
    @include tr(color .3s);
    @include hover {
      color: $accent; }
    @include lg {
      display: block; } } }



.header-contacts {
  position: relative;
  color: $c-title;
  padding-left: 20px;
  &__icon {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    .icon {
      font-size: 1.1em; } }
  &__content {
    a {
      @include tr(color .3s);
      @include hover {
        color: $accent; } } } }

.work-hrs {
  font-size: $fz-xs;
  @include flex(flex-start, center);
  &__icon {
    margin-right: .3em;
    .icon {
      font-size: 1.3em; } }
  &__content {
    @include flex(flex-start, center); } }

.burger {
  display: inline-block;
  width: 28px;
  height: 28px;
  // margin-top: -24px
  position: relative;
  @include tr(color .3s);
  @include hover {
    color: $accent; }
  span:after, span:before {
    content: "";
    position: absolute;
    left: 0; }
  span:after {
    top: 5px; }
  span:before {
    bottom: 5px; }
  span {
    // position: relative
    display: block; }
  span, span:after, span:before {
    width: 100%;
    height: 2px;
    background-color: currentColor;

    backface-visibility: hidden;
    border-radius: 2px; }

  &--arrow {
    background-color: $accent;
    @include before {
      @include icon-arrow(10, 10, 2, $white, 225);
      position: static;
      display: inline-block;
      margin-left: -.2em; } } }
