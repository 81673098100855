.hero {
  @include cover;
  &__inner {
    padding-top: 15%;
    padding-bottom: 15%;
    @include xs {
      padding-top: 100px;
      padding-bottom: 100px; } }
  &__title {
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__subttl {
    margin-bottom: 15px;
    color: $white;
    @include md {
      margin-bottom: 10px; } }
  &__text {
    color: #d7d7d7; }
  &__title-block {
    padding-top: 65px;
    padding-bottom: 75px;
    @include xl {
      padding-bottom: 65px; }
    @include lg {
      padding-top: 50px;
      padding-bottom: 50px; } }
  &__breadcrumbs {
    padding-top: 20px; } }
