body {
  background: $white;
  line-height: 1.4;
  font-size: $fz;
  min-width: 320px;
  font-family: 'montserrat', Arial, sans-serif;
  color: $c-text;
  @include no-scroll;
  @include sm {
    font-size: $fz-sm-px; } }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.lazy {
  &.is-loaded:not(.no-fade-in) {
    animation: fadeIn 1s; } }

.container {
  max-width: 1440px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto; }

@for $i from 1 through 12 {
  .col-xll-#{$i} {
    @include xlmin {
      flex: 0 0 percentage($i/12);
      max-width: percentage($i/12); } } }

.logo {
  display: inline-block;
  font-size: 0;
  @include sm {
    width: 100px; }
  @include xss {
    width: 80px; }
  img {
    max-height: 100%; } }

.out {
  @include lg {
    padding-top: $header-height-lg; }
  @include sm {
    padding-top: $header-height-sm; } }

.text-block {
  $this: &;
  margin-bottom: 45px;
  @include lg {
    margin-bottom: 30px; }
  @include md {
    margin-bottom: 15px; }
  &__upttl {
    text-transform: uppercase; }
  &__title, &__upttl {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 15px; } }
  &__text {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 15px; } }

  &--sm {
    margin-bottom: 30px;
    #{$this}__title {
      margin-bottom: 10px;
      @include md {
        margin-bottom: 10px; } }
    // #{$this}__text
    //   margin-bottom: 10px
    //   +md
 } }    //     margin-bottom: 10px


.link {
  @include inline-flex(flex-start, center);
  position: relative;
  color: $accent;
  text-transform: uppercase;
  font-weight: 700;
  font-size: $fz-xs;
  &__title {
    margin-right: 1em; }
  &__arrow {
    position: relative;
    width: 20px;
    display: block;
    @include after {
      @include icon-arrow(8, 8, 2, currentColor, 225);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%) rotate(225deg);
      right: 0; }
    @include before {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
      border-top: 2px solid currentColor;
      left: 0; } }
  &--sm {
    text-transform: none;
    text-decoration: underline;
    font-size: $fz-sm;
    font-weight: 400; } }

.tag {
  display: inline-block;
  background-color: $accent;
  text-transform: uppercase;
  font-weight: 600;
  font-size: em(21);
  padding: .1em 2.15em 0;
  color: $white; }

.meta {
  font-size: $fz-xs;
  @include flex(flex-start, center);
  .icon {
    top: .15em;
    position: relative;
    font-size: 1.1em;
    margin-right: .3em; }
  &__item {
    @include notlast {
      margin-right: 1em; } } }

blockquote {
  border-left: 3px solid  $accent;
  padding: 25px;
  font-style: italic;
  background-color: $gray-lightest; }

.map {
  position: relative;
  padding-top: 60%;
  background-color: $gray;
  iframe {
    @include coverdiv; }
  @include xss {
    padding-top: 80%; } }

.descr-list {
  @include clr;
  dt {
    float: left;
    margin-right: .5em; } }

