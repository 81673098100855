.work-link {
  $this: &;
  display: block;
  padding-top: 55%;
  background-color: $gray;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  @include cover;
  position: relative;
  color: $white;
  @include hover {
    #{$this}__link {
      @include visible; } }
  @include md {
    margin-bottom: 15px;
    height: calc(100% - 15px); }
  @include xs {
    height: auto; }

  &__cover {
    @include coverdiv;
    @include flex(center, center, column);
    background-color: rgba($black, .5);
    @include tr(visibility .3s, opacity .3s); }

  &__text {
    display: block;
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px; }
  &__link {
    // +hidden
    @include tr(visibility .3s, opacity .3s); }

  &--md {
    padding-top: 60%; } }
