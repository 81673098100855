.prev, .next {
  @include inline-flex(center, center);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: $white;
  color: $c-title;
  position: relative;
  border: 1px solid $gray-light;
  @include before {
    @include icon-arrow(.6, .6, .06, currentColor, 45, em);
    display: block;
    margin-left: .3em; }
  @include sm {
    width: 30px;
    height: 30px; }
  &[aria-disabled="true"] {
    opacity: .5; } }

.next {
  transform: scaleX(-1); }

.slider {
  &__wrap {
    position: relative;
    * {
      box-sizing: border-box; } }
  &__controls {
    @extend .container;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0;
    z-index: 2;
    @include flex(space-between, center); }
  &__prev, &__next {
    border: 0; }
  &__pagination {
    position: absolute;
    z-index: 1;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    display: none;
    @include sm {
      display: block; }
    .swiper-pagination-bullet {
      display: inline-block;
      opacity: 1;
      margin-right: 5px;
      margin-left: 5px;
      width: 10px;
      height: 10px;
      background-color: $gray;
      border-radius: 50%;
      &.swiper-pagination-bullet-active {
        background-color: $accent; } } }

  &--hero {
    .slide {
      @include cover; }
    &__wrap {
      .slider {
        &__controls {
          @include sm {
            display: none; } } } } } }


