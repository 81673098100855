.form {
  $this: &;
  &:not(.form--call) {
    background-color: $white;
    padding: 60px;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
    @include xll {
      padding: 50px; }
    @include xl {
      padding: 40px; }
    @include lg {
      padding-left: 30px;
      padding-right: 30px; }
    @include md {
      padding-left: 20px;
      padding-right: 20px; }
    @include sm {
      padding-left: 15px;
      padding-right: 15px; } }
  &__title {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; } }
  &__field {
    @include notlast {
      margin-bottom: 25px;
      @include md {
        margin-bottom: 15px; } } }
  &__rating {
    @include flex(flex-start, center);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: em(15);
    > label {
      display: block;
      margin-right: .5em; } }
  &__row {
    @include flex(flex-start, normal, row, wrap); }
  &__btn {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    @include xs {
      max-width: none; } }

  &--call {
    .input {
      max-width: 400px;
      width: 100%;
      margin-right: 15px;
      @include sm {
        max-width: none;
        margin-bottom: 15px;
        margin-right: 0; } }
    .btn {
      @include sm {
        min-width: 0;
        width: 100%; } } }
  &--rating {
    box-shadow: none !important;
    background-color: transparent !important;
    text-align: left;
    padding: 0 !important;
    &__wrap {
      background-color: $gray-lightest;
      padding: 40px;
      @include lg {
        padding: 30px; }
      @include md {
        padding: 20px; } }
    #{$this}__field {
      @include notlast {
        margin-bottom: 5px;
        @include md {
          margin-bottom: 5px; } } }
    #{$this}__btn {
      margin-left: 0;
      margin-right: 0; } } }

.input, .textarea {
  input, textarea {
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid  $gray-light;
    height: 35px;
    padding: .3em 0;
    @include tr(border-color .3s);
    &:focus {
      border-color: $accent; }
    @include placeholder {
      color: $gray-light; } }
  textarea {
    height: auto;
    min-height: 100px; }

  &--white {
    input, textarea {
      background-color: $white;
      border: 1px solid $white;
      padding: .8em 1.5em;
      height: 51px; } }
  &--border {
    input, textarea {
      border: 1px solid  $gray-light;
      height: em(50);
      padding: em(13) em(15);
      background-color: $white; }
    textarea {
      height: auto;
      min-height: 100px; } } }

.textarea {
  display: flex; }

.checkbox {
  position: relative;
  font-size: $fz-xs;
  padding-left: 1.5em;
  user-select: none;
  color: $gray-light;
  input[type="checkbox"] {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 0;
    &:checked + span {
      &::after {
        content: '';
        border-radius: 50%;
        background-color: $accent;
        height: 7px;
        width: 7px;
        top: 2px;
        left: 4px;
        position: absolute;
        display: block; } } }
  span {
    &::before {
      content: '';
      width: 15px;
      height: 15px;
      top: -2px;
      left: 0;
      background-color: #eeeeee;
      border-radius: 50%;
      position: absolute;
      display: block; } } }
