.service-link {
  @include flex(flex-end, normal, column);
  padding: 100px 30px 30px;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  color: $white;
  @include cover;
  @include lg {
    padding: 100px 20px 20px; }
  @include md {
    height: calc(100% - 15px);
    margin-bottom: 15px; }
  @include sm {
    padding: 100px 15px 15px; }
  @include xs {
    height: auto; }
  &__content {
    display: block; }
  &__title {
    display: block;
    margin-bottom: 10px;
    @include md {
      margin-bottom: 5px; } }
  &__text {
    display: block;
    margin-bottom: 10px;
    max-width: 320px;
    font-size: $fz-sm;
    @include md {
      margin-bottom: 5px; } } }

.services-sm {
  margin-bottom: -30px;
  @include xs {
    .row {
      margin-left: -5px;
      margin-right: -5px; }
    [class*="col-"] {
      padding-left: 5px;
      padding-right: 5px; } } }
.service-sm {
  height: calc(100% - 30px);
  @include flex(flex-start, normal, column);
  margin-bottom: 30px;

  &__icon {
    color: $accent;
    margin-bottom: 5px;
    .icon {
      font-size: em(45); }
    .icon-menu {
      font-size: em(35); } }

  &__body {
    flex-grow: 1;
    text-align: center;
    padding: 20px;
    border: 1px solid  #e8e8e8;
    @include flex(center, normal, column); }
  &__footer {
    text-align: center;
    background-color: #e8e8e8;
    // padding: 10px
    font-size: 1.4em;
    font-weight: 600; } }

