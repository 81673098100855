.pay-method {
  background-color: $white;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.18);
  padding: 40px 70px;
  text-align: center;
  margin-bottom: 15px;
  height: calc(100% - 15px);
  @include flex(flex-start, normal, column);
  @include xl {
    padding: 30px 50px; }
  @include lg {
    padding: 30px 40px; }
  @include md {
    padding: 30px; }
  @include sm {
    padding: 20px; }
  @include xs {
    height: auto; }
  &__title {
    margin-bottom: 15px; }
  &__img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px dashed $gray-light;
    @include md {
      width: 180px;
      height: 180px; }
    @include sm {
      width: 150px;
      height: 150px; }
    img {
      @include vcenter;
      max-width: 105%; }
    @include md {
      margin-bottom: 15px; } }
  &__text {
    flex-grow: 1; } }
