.testimonial {
  padding: 35px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
  background-color: $gray-lightest;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  @include lg {
    padding: 30px; }
  @include md {
    padding: 25px; }
  @include sm {
    padding: 15px;
    margin-bottom: 15px;
    height: auto; }
  &__top {
    @include flex(space-between, center);
    margin-bottom: 25px;
    @include md {
      margin-bottom: 15px; } }
  &__title {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 10px; } }
  &__text {
    margin-bottom: 10px; } }
