.nav {
  color: $white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: $fz-sm;
  li {
    display: inline-block;
    margin-left: 45px;
    margin-right: 45px;
    position: relative;
    @include hover {
      > ul {
        // +visible
        display: block; }
      > a {
        border-bottom-color: $white;
        background-color: transparent;
        color: $gray-light; } }
    @include xl {
      margin-left: 25px;
      margin-right: 25px; }
    @include lg {
      margin-left: 0;
      margin-right: 0;
      display: block;
      @include notlast {
        margin-bottom: 15px; } }
    > ul {
      position: absolute;
      z-index: 1;
      background-color: rgba($black, .9);
      left: 0;
      // transform: translate(-50%, 0)
      top: 100%;
      text-align: left;
      // padding-top: 15px
      // padding-bottom: 15px
      width: 300px;
      // max-height: 0
      // overflow: hidden
      display: none;
      @include ready {
        @include tr(visibility .3s, opacity .3s, max-height .3s, margin .3s); }
      &.is-open {
        margin-top: 15px;
        margin-bottom: 15px;
        display: block; }
      @include lgmin {
        // +hidden
        display: none;
        max-height: none;
        padding-top: 15px;
        padding-bottom: 15px; }
      @include lg {
        position: static;
        width: auto;
        background-color: transparent;
        text-align: center;
        font-size: .8em;
        padding-bottom: 0; }
      li {
        margin-left: 0 !important;
        margin-right: 0 !important;
        display: block;
        > ul {
          left: 100%;
          top: 0; } }
      a {
        height: auto;
        justify-content: flex-start;
        padding-left: 15px;
        padding-right: 15px;
        @include hover {
          border-bottom-color: transparent; } } }
    &.current, &.active {
      > a {
        background-color: $accent; } }
    &.parent {
      > a {
        @include after {
          @include icon-arrow(.7, .7, .05, currentColor, -45, em);
          display: inline-block;
          margin-left: .7em;
          position: relative;
          top: -.2em; } } } }
  a {
    @include flex(center, center);
    padding: .5em;
    border-bottom: 4px solid transparent;
    height: $header-bottom-height;
    @include tr(color .3s, border-color .3s);
    @include xll {
      height: $header-bottom-height-xll; }
    // +hover
    //   border-bottom-color: $white
    //   color: $gray-light
    @include lg {
      display: inline;
      // padding: 0
      border: 0; }
    &.is-active {
      border-bottom-color: $white;
      color: $gray-light; } }

  &--footer {
    color: $gray;
    text-transform: none;
    font-weight: 400;
    font-size: 1em;
    li {
      display: block;
      margin-left: 0 !important;
      margin-right: 0 !important;
      @include notlast {
        margin-bottom: 15px;
        @include md {
          margin-bottom: 10px; } } }
    a {
      display: inline;
      padding: 0;
      border-bottom: 0;
      @include hover {
        color: $c-text; } } }
  &--under {
    li {
      border-bottom: 1px dashed currentColor;
      @include flex(space-between);
      @include lg {
        display: flex; } } } }

