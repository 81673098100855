.btn {
  @include inline-flex(center, center);
  color: $white;
  text-align: center;
  background-color: $accent;
  border: 1px solid  $accent;
  font-weight: 600;
  padding: .85em;
  min-width: 300px;
  height: 51px;
  @include tr(background-color .3s, border-color .3s, color .3s);
  @include hover {
    background-color: transparent;
    color: $accent; }
  // +lg
  //   padding: .7em
  //   height: 45px
  @include xs {
    min-width: 0;
    width: 100%; }

  &--full {
    min-width: 0;
    width: 100%; } }

.btn-simple {
  color: $accent;
  font-weight: 600;
  @include hover {
    text-decoration: underline; }
  .icon {
    margin-right: .4em;
    position: relative;
    top: .2em; } }
