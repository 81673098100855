.popup {
  $this: &;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: rgba($black, .7);
  overflow: auto;
  padding: 15px;
  @include vertical;
  @include hidden;
  @include ready {
    @include tr(opacity .3s, visibility .3s); }
  &.is-active {
    @include visible; }
  &__inner {
    background-color: $white;
    display: inline-block;
    vertical-align: middle;
    font-size: $fz;
    width: 100%;
    max-width: 930px;
    position: relative;
    padding: 60px;
    @include lg {
      padding: 40px; }
    @include md {
      padding: 20px; }
    @include sm {
      font-size: $fz-sm-px; } }
  &__close {
    color: $c-title;
    @include icon-close(15, 1, currentColor);
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px; }
  &__content {
    max-width: 410px;
    padding: 70px 0;
    text-align: left;
    @include md {
      max-width: 320px; } }

  &--sm {
    #{$this}__inner {
      padding: 0;
      max-width: 450px;
      @include lg {
        padding: 0; }
      @include md {
        padding: 0; } } }
  &--color {
    #{$this}__inner {
      @include cover;
      background-position: 15% 50%; }
    #{$this}__close {
      color: $white; } } }

