.features {
  $this: &;

  &--sm {
    @include flex(center, normal, row, wrap);
    #{$this}__item {
      width: 33.33333%;
      padding-right: 10px;
      @include flex(center);
      border-right: 1px dashed $gray-light;
      @include xs {
        width: 50%;
        padding-right: 0;
        border-right: 0; }
      &:nth-child(1) {
        justify-content: flex-start;
        @include md {
          justify-content: center; } }
      &:last-child {
        border-right: 0; } } } }


.feature {
  $this: &;
  text-align: center;
  margin-bottom: 30px;
  &__title {
    margin-bottom: 10px;
    @include md {
      margin-bottom: 5px; } }
  &__icon {
    @include flex(center, center);
    margin-left: auto;
    margin-right: auto;
    background-color: $white;
    border-radius: 50%;
    color: $accent;
    border: 1px dashed $gray-light;
    width: 75px;
    height: 75px;
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; }
    .icon {
      font-size: em(40); } }
  &__text {
    font-size: $fz-sm; }
  &--sm {
    #{$this}__text {
      text-transform: uppercase;
      font-size: $fz-xs; }
    #{$this}__title {
      margin-bottom: 5px; } } }
