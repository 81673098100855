.article {
  color: $c-title;
  text-align: justify;
  @include clr;
  h2 {
    @extend .title;
    @extend .title--h3;
    @extend .title--ttn; }
  h3 {
    @extend .title;
    @extend .title--h5;
    @extend .title--ttn; }
  p, h1, h2, h3, h4, h5, h6, ul, ol, blockquote {
    @include notlast {
      margin-bottom: 25px;
      @include md {
        margin-bottom: 15px; } } }
  h1, h2, h3, h4, h5, h6 {
    text-align: left; }
  img {
    float: right;
    margin-left: 30px;
    margin-bottom: 30px;
    @include md {
      width: 100%;
      float: none;
      margin-left: 0;
      margin-bottom: 15px; } }

  ol {
    list-style-type: decimal;
    margin-left: 1.1em; }

  ul {
    li {
      &::before {
        content: "\2022";
        display: inline-block;
        margin-right: .5em; } } }
  a {
    color: $blue; } }

