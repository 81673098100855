.gallery {
  $this: &;
  // +flex(flex-start, normal, row, wrap)
  &__slider {
    margin-bottom: 5px; }

  &__thumbs {
    @include flex(center, normal, column);
    @include sm {
      width: 100%;
      padding-left: 0; } }
  &__img {
    padding-top: 40%;
    position: relative;
    @include cover;
    @include hover {
      #{$this}__img-cover {
        @include visible; } }
    @include xs {
      padding-top: 60%; } }
  &__thumb {
    padding-top: 11%;
    display: block;
    @include cover;
    @include xs {
      padding-top: 15%; } }

  &__img-cover {
    @include coverdiv;
    background-color: rgba($black, .7);
    z-index: 1;
    pointer-events: none;
    @include flex(center, center);
    @include hidden;
    @include tr(visibility .3s, opacity .3s); }
  &__img-icon {
    border-radius: 50%;
    color: $black;
    background-color: rgba($white, .5);
    @include flex(center, center);
    width: 45px;
    height: 45px;
    .icon {
      font-size: 21px; } }

  &--height {
    height: 100%;
    @include flex(flex-start, normal, column);
    @include xl {
      margin-bottom: 30px;
      height: auto; }
    #{$this}__slider {
      flex-grow: 1;
      .slider__wrap, .swiper-container {
        height: 100%; } } } }
