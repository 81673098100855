.section {
  padding-top: 110px;
  padding-bottom: 110px;
  @include cover;
  @include xl {
    padding-top: 80px;
    padding-bottom: 80px; }
  @include lg {
    padding-top: 60px;
    padding-bottom: 60px; }
  @include md {
    padding-top: 50px;
    padding-bottom: 50px; }
  @include sm {
    padding-top: 30px;
    padding-bottom: 30px; }
  &__title {
    text-align: center;
    margin-bottom: 40px;
    .title {
      margin-bottom: 15px; }
    @include lg {
      margin-bottom: 20px; }
    @include md {
      margin-bottom: 15px; } }
  &__text {
    margin-bottom: 40px;
    @include lg {
      margin-bottom: 20px; }
    @include md {
      margin-bottom: 15px; } }
  &__subttl {
    color: $c-title; }
  &__btn {
    text-align: center; }
  &__aside-toggle {
    display: none;
    margin-bottom: 15px;
    @include md {
      display: block; } }
  &__aside {
    position: sticky;
    top: $header-bottom-height + 15px;
    @include md {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      background-color: rgba($black, .7);
      @include hidden;
      @include tr(opacity .3s, visibility .3s);
      .aside {
        @include ready {
          @include tr(transform .3s); }
        transform: translate(-100%, 0); }
      &.is-active {
        @include visible;
        .aside {
          transform: translate(0, 0); } } } }
  &__block {
    @include notlast {
      margin-bottom: 35px;
      @include md {
        margin-bottom: 25px; } } }


  &--sm {
    padding-top: 45px;
    padding-bottom: 45px;
    @include xl {
      padding-top: 45px;
      padding-bottom: 45px; }
    @include lg {
      padding-top: 45px;
      padding-bottom: 45px; }
    @include md {
      padding-top: 45px;
      padding-bottom: 45px; }
    @include sm {
      padding-top: 30px;
      padding-bottom: 30px; } }
  &--gray-lightest {
    background-color: $gray-lightest; } }

.s-order {
  @include md {
    background-image: none !important;
    background-color: $gray-lightest; }
  &__text-block {
    max-width: 520px;
    @include md {
      text-align: center;
      margin-left: auto;
      margin-right: auto; } }
  &__features {
    @include md {
      margin-bottom: 30px; } } }

.s-banner {
  @include md {
    background-image: none !important;
    background-color: $gray-lightest; }
  &__text-block {
    @include md {
      margin-bottom: 40px; } } }
