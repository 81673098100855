.steps {
  @include md {
    margin-bottom: 30px; } }

.step {
  position: relative;
  @include css-lock(50, 95, 320, 1399, padding-left);
  @include notlast {
    margin-bottom: 45px;
    @include lg {
      margin-bottom: 35px; }
    @include md {
      margin-bottom: 20px; } }
  &__content {
    color: $c-title;
    a {
      color: $blue; } }
  &__nmb {
    @extend .title;
    @extend .title--h2;
    position: absolute;
    top: 0;
    left: 0;
    border-left: 1px dashed $gray-light;
    padding-left: .35em;
    padding-right: .35em;
    padding-top: .2em;
    padding-bottom: .1em;
    html:not(.is-ie) & {
      color: transparent;
      -webkit-text-stroke: 2px $c-text; } } }
