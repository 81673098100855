.overflow-hidden {
  overflow: hidden; }

.accent {
  color: $accent; }

.pb-0 {
  padding-bottom: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.is-hidden-sm {
  @include sm {
    display: none !important; } }

.text-center {
  text-align: center; }
.text-left {
  text-align: left; }
